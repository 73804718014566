import { useNavigate } from "react-router-dom"

export default function About(){
    const nav = useNavigate()
    return(
        <div style={{paddingTop:'25%'}}>
            <button style={{left:'2%',top:'10%',position:'absolute',fontSize:'1rem'}} onClick={()=>{nav('/')}}>{'<'}</button>
            <div style={{padding:'5rem',backgroundColor:'white',width:'10vw',display:'inline-flex',borderRadius: '25px',flexDirection:'column'}}>
                <h2>About</h2>
                This is the website of Clayton Bruce.
            </div>
        </div>
    )
}