import { useNavigate } from "react-router-dom";
import { images } from "../images/images";

export default function ImageDisplay(){
    const showImages = true
    const nav = useNavigate()
    return(
    <div style={{overflow: 'scroll', height:'100vh'}}>
        <div style={{paddingBottom:'150vh',paddingTop:'10vh',paddingLeft:'2%'}}>
            <div style={{display: 'grid', gridTemplateColumns:'30% 30% 30%',gridGap:'3%'}}>
                {showImages?
                <>{images.map((image,i)=>
                    <div style={{height:'36%'}} key={i} onClick={()=>{nav(`/paintings/${i}`)}}>
                        <div key={i}>Painting #{i}</div>
                        <div><img style={{backgroundColor: '#ffeaf4',padding:'8%',borderRadius: '4%'}} width={'90%'} height={'auto'} src={image.img} alt={`Painting #${i} of Clayboy Magazine`}/></div>
                    </div>
                )}</>
                :
                <></>}
            </div>
        </div>
    </div>
    )
}