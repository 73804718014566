import img0 from './IMG_0.jpg'
import img1 from './IMG_1.jpg'
import img2 from './IMG_2.jpg'
import img3 from './IMG_3.jpg'
import img4 from './IMG_4.jpg'
import img5 from './IMG_5.jpg'
import img6 from './IMG_6.jpg'
import img7 from './IMG_7.jpg'
import img8 from './IMG_8.jpg'
import img9 from './IMG_9.jpg'
import img10 from './IMG_10.jpg'
import img11 from './IMG_11.jpg'
import img12 from './IMG_12.jpg'
import img13 from './IMG_13.jpg'
import img14 from './IMG_14.jpg'
import img15 from './IMG_15.jpg'
import img16 from './IMG_16.jpg'
import img17 from './IMG_17.jpg'
import img18 from './IMG_18.jpg'
import img19 from './IMG_19.jpg'
import img20 from './IMG_20.jpg'
import img21 from './IMG_21.jpg'
import img22 from './IMG_22.jpg'
import img23 from './IMG_23.jpg'
import img24 from './IMG_24.jpg'
import img25 from './IMG_25.jpg'
import img26 from './IMG_26.jpg'
import img27 from './IMG_27.jpg'

export const images = [
    {img:img16,downloadable:false,printable:false,sold:true,desc:`I'm not sure the first time I noticed the beauty of these cranes, but it was peripheral`},
    {img:img17,downloadable:false,printable:false,sold:true,desc:`at first it was its towering nature, or something to do with corporate penis size...`},
    {img:img18,downloadable:false,printable:false,sold:true,desc:`But now I see that it is something else.`},

    {img:img19,downloadable:false,printable:false,sold:true,desc:`They combine into a beauty that illuminates.`},
    {img:img26,downloadable:false,printable:false,sold:true,desc:`goodbye cranes`},
    {img:img20,downloadable:false,printable:false,sold:false},

    {img:img21,downloadable:false,printable:false,sold:false},
    {img:img22,downloadable:false,printable:false,sold:false},
    {img:img23,downloadable:false,printable:false,sold:false},

    {img:img24,downloadable:false,printable:false,sold:false},
    {img:img25,downloadable:false,printable:false,sold:false},
    {img:img1,downloadable:false,printable:false,sold:false},

    {img:img27,downloadable:false,printable:false,sold:false},
    {img:img0,downloadable:false,printable:false,sold:false},
    {img:img2,downloadable:false,printable:false,sold:false},

    {img:img3,downloadable:false,printable:false,sold:false},
    {img:img12,downloadable:false,printable:false,sold:false},
    {img:img5,downloadable:false,printable:false,sold:false},

    {img:img4,downloadable:false,printable:false,sold:false},
    {img:img7,downloadable:false,printable:false,sold:false},
    {img:img8,downloadable:false,printable:false,sold:false},

    {img:img9,downloadable:false,printable:false,sold:false},
    {img:img10,downloadable:false,printable:false,sold:false},
    {img:img11,downloadable:false,printable:false,sold:false},

    {img:img6,downloadable:false,printable:false,sold:false},
    {img:img13,downloadable:false,printable:false,sold:false},
    {img:img14,downloadable:false,printable:false,sold:false},

    {img:img15,downloadable:false,printable:false,sold:false}]