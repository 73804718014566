import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import ImageDisplay from './components/ImageDisplay';
import NavBar from './components/NavBar/NavBar';
import SingleImageDisplay from './components/SingleImageDisplay';
import About from './components/About';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <NavBar/>
      <Routes>
        <Route path='/' element={<ImageDisplay/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/paintings/:id' element={<SingleImageDisplay/>}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;