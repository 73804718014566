import { redirect, useNavigate, useParams } from "react-router-dom"
import { images } from "../images/images"
import { links } from "./links";

export default function SingleImageDisplay () {
    const {id} = useParams();
    const nav = useNavigate()
    return(
        <div style={{overflow: 'scroll'}}>
            <div style={{ height:'100vh',width:'60%',display:'inline-flex',flexDirection:'column',}}>
                <div style={{display:'inline-flex',flexDirection:'column'}}>
                    <button style={{left:'2%',top:'10%',position:'absolute',fontSize:'1rem'}} onClick={()=>{nav('/')}}>{'<'}</button>
                    <button style={{left:'2%',top:'50vh',position:'absolute',fontSize:'1rem'}} onClick={()=>{nav(`/paintings/${parseInt(id)>0?parseInt(id)-1:images.length-1}`)}}>{'<'}</button>
                    <button style={{right:'2%',top:'50vh',position:'absolute',fontSize:'1rem'}} onClick={()=>{nav(`/paintings/${parseInt(id)+2>images.length?0:parseInt(id)+1}`)}}>{'>'}</button>

                    <div style={{backgroundColor: '#ffeaf4'}}>
                        <p>Painting #{id} of Clayboy Magazine</p>

                    <div className="imageArea" style={{position:'relative',top:'2%'}}>
                        <img style={{borderRadius: '1%'}}  width={'70%'} height={'auto'} src={images?.[id]?.img} alt={`Painting #${id} of Clayboy Magazine`}/>
                        
                    </div>

                    <div className="Descs" >
                        <div className="PaintingDescrition">
                            <h1>{images?.[id]?.sold? 'SOLD':''}</h1>
                        </div>
                        <div className="PaintingDescrition">
                            {images?.[id]?.desc}
                        </div>
                        <div style={{display:'inline-flex',flexDirection:'column',width:'25%',padding:'1rem',paddingBottom:'10vh'}}>
                            <br/>
                            {images?.[id]?.downloadable?<a href=''>Buy This Digitally Downloadable Painting</a>:<></>}
                            <br/>
                            {images?.[id]?.printable?<a href=''>Buy This Physical Print of a Physical Painting</a>:<></>}
                            <br/>
                            {!images?.[id]?.sold&&false?<a href=''>Buy This Physical Painting</a>:<></>}
                            <br/>
                        </div>
                    </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}