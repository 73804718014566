import { useNavigate } from 'react-router-dom'
import './NavBar.css'
import { useState } from 'react';
export default function NavBar() {
    const navigate = useNavigate();
    const [signedIn, setSignedIn] = useState(false)
    const showItems = true
    return (
    <div className="NavBar">
        <div className="NavbarIcon">CLAYBOY MAGAZINE</div>
        {showItems? 
        <div className="NavbarList">
            <div className="NavbarItem" onClick={()=>navigate('about')}>{'About'}</div>
            <div className="NavbarItemEmpty"></div>
        </div>
        :<></>}
        
    </div>
    )
}